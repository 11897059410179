import React, { FC, useEffect } from 'react';
import {
    HomeQuery,
    HomeQueryVariables,
    SponsorsQuery,
    SponsorsQueryVariables,
    useHomeQuery,
    useProgramPathQuery,
    useSponsorsQuery,
} from '../gql/cms';
import siteHandleByLocale from '../utils/siteHandleByLocale';
import { useRouter } from 'next/router';
import Error from 'next/error';
import { GetStaticProps } from 'next';
import { dehydrate, QueryClient } from '@tanstack/react-query';
import loadIntlMessages from '../utils/loadIntlMessages';
import { DEFAULT_REVALIDATION_TIME } from './[...uri]';
import {
    fetchApiData,
    fetchCraftData,
    getMetaForArticle,
    gtm,
    PreviewData,
    SponsorsBanner,
    SponsorSet,
    useGetCmsImageProps,
} from 'shared';
import {
    Body,
    Box,
    Button,
    Card,
    ChakraGrid,
    Cta,
    CtaWidget,
    DestinationListItem,
    Flex,
    getColorSchemeFromString,
    Grid,
    HomeHero,
    ListDivider,
    NewsListItem,
    Stack,
    TextButton,
    Wrapper,
} from 'designsystem';
import { useTheme } from '@emotion/react';
import { FormattedMessage, useIntl } from 'react-intl';
import Link from 'next/link';
import prefetchGlobals from '../utils/prefetchGlobals';
import { UpcomingShowsQuery, UpcomingShowsQueryVariables, useUpcomingShowsQuery } from '../gql/api';
import CinemaWidget from '../components/cinema/CinemaWidget';
import fetchProgramUri from '../utils/fetchProgramUri';

type Homepage = Extract<HomeQuery['entry'], { __typename?: 'instituteHomepage_instituteHomepage_Entry' }>;
type HomeNewsCard = Extract<Homepage['homeHeroCards'][number], { __typename?: 'homeHeroCards_cards_BlockType' }>;

const isNewsCard = (card: Homepage['homeHeroCards'][number]): card is HomeNewsCard =>
    card.__typename === 'homeHeroCards_cards_BlockType';

const renderCta = (
    cta: Homepage['homeCtas'][number],
    getImgProps: ReturnType<typeof useGetCmsImageProps>,
    inverted: boolean
) => (
    <Cta
        title={cta.heading}
        colorScheme={getColorSchemeFromString(cta.color)}
        image={getImgProps(cta.image[0])}
        inverted={inverted}
    >
        <Body>{cta.description}</Body>
        <Stack spacing={3}>
            {cta.links.map(link => (
                <Link
                    href={(link.linkObject.element?.uri ?? link.linkObject.url) || '/404'}
                    target={!link.linkObject.element?.uri ? '_blank' : undefined}
                    key={link.id}
                >
                    <TextButton as="div" size="s">
                        {link.label}
                    </TextButton>
                </Link>
            ))}
        </Stack>
    </Cta>
);

const Home: FC = () => {
    const { locale, asPath, isFallback } = useRouter();
    const { data } = useHomeQuery({ site: [siteHandleByLocale[locale]] });
    const { data: upcomingShowsData } = useUpcomingShowsQuery();
    const upcomingCinemaShows = upcomingShowsData?.upcomingCinemaShows;
    const entry = data?.entry;
    const articles = data?.instituteNewsArticlesEntries;
    const newsOverview = data?.newsOverview;
    const getImgProps = useGetCmsImageProps();
    const theme = useTheme();
    const intl = useIntl();
    const programUri = useProgramPathQuery(
        {
            site: siteHandleByLocale[locale],
        },
        { enabled: !isFallback }
    ).data?.entry?.uri;

    useEffect(() => {
        gtm.pageView(asPath, {
            page_pillar: 'institute',
            page_language: locale,
        });
    }, [asPath, locale]);
    const { formatMessage } = useIntl();

    if (entry?.__typename !== 'instituteHomepage_instituteHomepage_Entry') {
        return <Error statusCode={500} title="No homepage entry found in craft" />;
    }

    const newsCards = entry.homeHeroCards.filter(isNewsCard);
    const firstNewsCard = newsCards[0];
    const secondNewsCard = newsCards[1];
    const firstCta = entry.homeCtas[0];
    const secondCta = entry.homeCtas[1];
    const widget: Homepage['homeHeroWidget'][number] = entry.homeHeroWidget[0];
    const sponsorSet = entry.sponsorBannerSet?.[0] as SponsorSet;

    return (
        <>
            <HomeHero
                slides={entry.homeHeroCarousel.map(slide => ({
                    description: slide.description,
                    image: getImgProps(slide.image[0], slide.image[0]?.alt, '100vw'),
                    key: slide.id,
                    title: slide.heading,
                    href: slide.linkObject?.element?.uri ?? slide.linkObject.url,
                }))}
            />
            <Box bg={[theme.tokens.SyntaxBackgroundNeutralDarkest, null, null, 'transparent']}>
                <Wrapper wide>
                    <Flex
                        position="relative"
                        zIndex={1}
                        alignItems={['stretch', null, null, 'flex-end']}
                        flexDir={['column', null, null, 'row']}
                    >
                        <>
                            {(firstNewsCard || secondNewsCard) && (
                                <ChakraGrid
                                    ml={['-5vw', null, null, null, 0]}
                                    mr={['-5vw', null, null, 0]}
                                    gridTemplateColumns={['1fr', null, 'repeat(2, 1fr)']}
                                    order={[1, null, null, 0]}
                                    flex={1}
                                >
                                    {firstNewsCard && (
                                        <Link
                                            href={
                                                (firstNewsCard.cardLink?.element?.uri ?? firstNewsCard.cardLink.url) ||
                                                '/404'
                                            }
                                        >
                                            <Card
                                                gridColumn={['1 / -1', '1 / span 1', '1 / span 1', '1 / span 1']}
                                                image={getImgProps(firstNewsCard.image[0])}
                                                title={firstNewsCard.heading}
                                                minH="100%"
                                            >
                                                <Body>{firstNewsCard.description}</Body>
                                            </Card>
                                        </Link>
                                    )}
                                    {secondNewsCard && (
                                        <Link
                                            href={
                                                (secondNewsCard.cardLink?.element?.uri ??
                                                    secondNewsCard.cardLink.url) ||
                                                '/404'
                                            }
                                        >
                                            <Card
                                                gridColumn={['1 / -1', '2 / span 1', '2 / span 1', '2 / span 1']}
                                                image={getImgProps(secondNewsCard.image[0])}
                                                title={secondNewsCard.heading}
                                                variant="white"
                                                outline="none !important"
                                                minH="100%"
                                            >
                                                <Body>{secondNewsCard.description}</Body>
                                            </Card>
                                        </Link>
                                    )}
                                </ChakraGrid>
                            )}

                            {widget && (
                                <Flex
                                    width={['calc(100% + 5vw)', null, null, '45vw', '33.3vw']}
                                    maxW={[null, null, null, '593px']}
                                    mr={['-5vw', null, null, null, '0']}
                                    shrink={0}
                                    position="relative"
                                >
                                    {widget.__typename === 'homeHeroWidget_upcomingInCinema_BlockType' && (
                                        <CinemaWidget
                                            heading={widget.heading}
                                            upcomingShows={upcomingCinemaShows}
                                            programUri={programUri}
                                        />
                                    )}
                                    {widget.__typename === 'homeHeroWidget_cta_BlockType' && (
                                        <Link
                                            href={widget.linkObject?.element?.uri ?? widget.linkObject.url}
                                            style={{ width: '100%' }}
                                        >
                                            <CtaWidget
                                                colorScheme={getColorSchemeFromString(widget.color)}
                                                title={widget.heading}
                                                description={widget.description}
                                                buttons={<Button as="div">{widget.buttonLabel}</Button>}
                                                width="100%"
                                                position={['static', null, null, 'absolute']}
                                                top={[0, null, null, 'auto']}
                                                bottom={0}
                                                left={0}
                                                right={0}
                                            />
                                        </Link>
                                    )}
                                </Flex>
                            )}
                        </>
                    </Flex>
                </Wrapper>
            </Box>
            <Box my={[13, null, 17]}>
                <Wrapper>
                    <Grid>
                        <Flex gridColumn={['1 / -1', null, '1 / span 5']}>
                            <Stack spacing={0} divider={<ListDivider />} w="100%">
                                {entry.homeDestinationList?.map(item => (
                                    <Link href={item.linkTo.element?.uri ?? item.linkTo.url ?? '/404'} key={item.id}>
                                        <DestinationListItem
                                            title={item.linkTo.element ? item.linkTo.element?.title : item.label}
                                            textButtonProps={{
                                                children: item.linkTo.element
                                                    ? item.label
                                                    : formatMessage({ defaultMessage: 'Lees meer' }),
                                            }}
                                        />
                                    </Link>
                                ))}
                            </Stack>
                        </Flex>
                        <Flex gridColumn={['1 / -1', null, '9 / -1']} alignItems="flex-end" flexDirection="column">
                            <SponsorsBanner maxW="368px" sponsorSet={sponsorSet as SponsorSet} />
                        </Flex>
                    </Grid>
                </Wrapper>
            </Box>
            {firstCta && (
                <Box my={[15, null, 17]}>
                    <Wrapper>{renderCta(firstCta, getImgProps, false)}</Wrapper>
                </Box>
            )}
            {articles?.length > 0 && (
                <Wrapper>
                    <Grid>
                        <Box gridColumn={['1 / -1', null, null, null, '3 / -3']}>
                            <Stack spacing={[6, null, 9]} divider={<ListDivider />} mb={[6, null, 9]}>
                                {articles.map(article => (
                                    <Link href={article.uri || '/404'} key={article.id}>
                                        <NewsListItem
                                            title={article.title}
                                            meta={getMetaForArticle(article, intl)}
                                            image={getImgProps(article.headerImage?.[0])}
                                        />
                                    </Link>
                                ))}
                            </Stack>

                            {newsOverview && (
                                <Box>
                                    <Link href={newsOverview.uri || '/404'}>
                                        <TextButton size="s" as="span">
                                            <FormattedMessage defaultMessage="Alle artikelen" />
                                        </TextButton>
                                    </Link>
                                </Box>
                            )}
                        </Box>
                    </Grid>
                </Wrapper>
            )}
            {secondCta && (
                <Box my={[15, null, 17]}>
                    <Wrapper>{renderCta(secondCta, getImgProps, true)}</Wrapper>
                </Box>
            )}
        </>
    );
};

export const getStaticProps: GetStaticProps = async ({ locale, previewData }) => {
    const queryClient = new QueryClient();
    const vars = { site: [siteHandleByLocale[locale]] };

    await queryClient.prefetchQuery(useUpcomingShowsQuery.getKey(), () =>
        fetchApiData<UpcomingShowsQuery, UpcomingShowsQueryVariables>({
            query: useUpcomingShowsQuery.document,
            locale,
        })
    );

    await queryClient.prefetchQuery(useHomeQuery.getKey(vars), () =>
        fetchCraftData<HomeQuery, HomeQueryVariables>({
            query: useHomeQuery.document,
            variables: vars,
            previewData: previewData as PreviewData,
        })
    );

    await fetchProgramUri(queryClient, locale);
    await prefetchGlobals(queryClient, locale);

    await queryClient.prefetchQuery(useSponsorsQuery.getKey({}), () =>
        fetchCraftData<SponsorsQuery, SponsorsQueryVariables>({
            query: useSponsorsQuery.document,
            variables: {},
            previewData: previewData as PreviewData,
        })
    );

    return {
        props: {
            dehydratedState: dehydrate(queryClient),
            intlMessages: await loadIntlMessages(locale),
        },
        revalidate: DEFAULT_REVALIDATION_TIME,
    };
};

export default Home;
